import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/PostLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`⚠ `}<em parentName="p">{`unfinished post`}</em></p>
    <p>{`This is a README of this website, describing the interesting bits of the
tech stack, the motivation behind it, and the goals of it.`}</p>
    <p>{`I encountered swyx’s
`}<a parentName="p" {...{
        "href": "https://www.swyx.io/writing/learn-in-public/"
      }}>{`Learn in Public`}</a>{` and realized
I’m doing something wrong. I was doing a heckload of notes. It was before I
found out about `}<a parentName="p" {...{
        "href": "https://roamresearch.com/"
      }}>{`Roam`}</a>{`, so my notes were a
chaotic mess of random Notion pages, Dropbox Paper docs and sheets I took
out of the printer to cover with illegible hieroglyphs. One future awaited
all of them. Forgotten and lost. Thrown away with the rubbish.`}</p>
    <p>{`“Maybe I can make them helpful for somebody else,” I thought. Spellcheck
them in Grammarly. Write a bunch of MDX files. Publish them with Gatsby.`}</p>
    <p>{`I started a blog. Turns out, blogging is hard for me. Polishing one piece of
notes to “blog post quality” took a few days of writing and two weeks of
minor fixes. I tried hard because I heard a lot about building a personal
brand. Somebody asked how many impressions I make and where do they come
from. What? I didn’t set up analytics. I don’t want to!`}</p>
    <p>{`I want to publish the good parts of my notes and link to cool stuff I find
on the internet. I want to have a personal website, like my former
`}<a parentName="p" {...{
        "href": "http://www.ii.uni.wroc.pl/~wzychla/"
      }}>{`lecturers`}</a>{` at the uni. A bit ugly. Practical and fun in a little weird way.`}</p>
    <p>{`Take a look at swyx.io. It has a big heading saying “SWYX’S SITE!“. The
about page is empty. These gradient link hovers aren’t pretty, but they put
a big smile on my face. It’s awesome.`}</p>
    <p>{`I’m gonna go this way. I want to stop spending half of my free evenings on
this website and have fun with it.`}</p>
    <p>{`Turns out I’m not the only one.`}</p>
    <p>{`Here are the articles that clarified the idea for me and inspired me in the
order I read them.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://joelhooks.com/digital-garden"
        }}>{`Joel Hooks’s Digital Garden`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tomcritchlow.com/2019/02/17/building-digital-garden/"
        }}>{`Tom Critchlow’s Building a digital garden`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://stackingthebricks.com/how-blogs-broke-the-web/"
        }}>{`Amy Hoy’s How the Blog Broke the Web`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://joelhooks.com/on-writing-more"
        }}>{`Joel Hooks’ On Writing More`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.brendanschlagel.com/2017/11/05/canonize-creating-personal-canon-template/"
        }}>{`Brendan Schlagels’s Canonize: Creating a Personal Canon Template`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/johno/digital-garden/blob/afe6e622fac33b33225b27071f627eb9475a6ed3/readme.md"
        }}>{`github.com/johno/digital-garden readme.md`}</a></li>
    </ul>
    <p>{`My goal isn’t to reach many and build my brand through a stream of targeted
blog posts. I want to just enjoy having this garden. This is what gardens
are about, right? Both pleasant and useful for their owner.
I’m`}{` `}{`tweaking it a bit here and there, and writing posts occasionally.`}</p>
    <p>{`I have encountered a few exciting problems while building it, and I’d love
to write more about them and the solutions for them that I have implemented.
I believe this website to be a good example of what is possible in a limited
time with the sheer power of Gatsby. The two most interesting things I did:`}</p>
    <ul>
      <li parentName="ul">{`Rendering social card images with React`}</li>
      <li parentName="ul">{`Collecting post commits to display a history of edits in a blog post`}</li>
    </ul>
    <p>{`I may turn them into standalone blog posts one day, but let me focus on the
latter one here.`}</p>
    <h3 {...{
      "id": "a-blog-post-git-history",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#a-blog-post-git-history",
        "aria-label": "a blog post git history permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`A Blog Post Git History`}</h3>
    <p>{`The publishing dates of posts are pretty problematic. I update my posts
sometimes. Should I change the date then?`}</p>
    <p>{`Kent C. Dodds asked why doesn’t he have dates on his blog posts `}<a parentName="p" {...{
        "href": "https://twitter.com/kentcdodds/status/1205885556091015168"
      }}>{`said`}</a>{` he
just keeps all of them up to date. Publish dates would be confusing. I get
that point, but I kind of want to keep the dates, at least for myself as a
`}<em parentName="p">{`created_at`}</em>{` information.`}</p>
    <p>{`I firmly believe that knowing the road is more valuable than knowing only
the destination.`}</p>
    <p>{`Instead of showing fewer dates, I decided to show more. I keep my posts in a
git repository, so I have a fairly good idea of what and when was changed.`}</p>
    <p>{`Using `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/docs/schema-customization/"
      }}>{`createSchemaCustomization`}</a>{`, I added a `}<inlineCode parentName="p">{`history`}</inlineCode>{` field to posts
frontmatter. This is nullable, so not all posts have history displayed and
for those which do, I’d like to decide whether we show full commits or just
a number of edits and a time span. You can find the `}<inlineCode parentName="p">{`Verbose`}</inlineCode>{` variant in my
`}<a parentName="p" {...{
        "href": "/reading"
      }}>{`reading list`}</a>{` and the `}<inlineCode parentName="p">{`DatesOnly`}</inlineCode>{` variant here, right below this
post.`}</p>
    <style>{`.vscode-highlight-line .mtk5.mtk5 { color: unset; }`}</style>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "graphql"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`Mdx`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`implements`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`Node`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`frontmatter`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`MdxFrontmatter`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`enum`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`BlogpostHistoryType`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk7"
          }}>{`  Verbose`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk7"
          }}>{`  DatesOnly`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`MdxFrontmatter`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`@dontInfer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`title`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`!`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`spoiler`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`!`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`date`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`Date`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`!`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`history`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`BlogpostHistoryType`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`Based on a code I copied from a gist, I have formatted the git log in a
parseable way. (`}<a parentName="p" {...{
        "href": "https://github.com/hasparus/homepage/blob/dcb621790da3e062d006d0c21a2c428ac99392fb/scripts/getGitLogJsonForFile.ts"
      }}>{`see the code`}</a>{`)`}</p>
    <p>{`Then, I have added the corresponding property to MDXFields.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "graphql"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`Mdx`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`implements`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`Node`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`frontmatter`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`MdxFrontmatter`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`fields`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`MdxFields`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`BlogpostHistoryEntry`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`abbreviatedCommit`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`String`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`authorDate`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`Date`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`!`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`subject`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`String`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`body`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`String`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`BlogpostHistory`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`entries`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: [`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`BlogpostHistoryEntry`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`!`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`]`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`!`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`url`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`!`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`MdxFields`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`route`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`!`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`history`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`BlogpostHistory`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`Connected it all together with about 50 lines of code and a one function
call in onCreateNode in `}<inlineCode parentName="p">{`gatsby-node-ts.ts`}</inlineCode>{`.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "ts"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`onCreateNode`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`GatsbyNode`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`[`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`onCreateNode`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`]`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` = async `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk44"
          }}>{`args`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` => {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`  const { `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`node`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`getNode`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` } = `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`args`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`  if `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(node`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk43"
          }}>{`internal`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` === `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`Mdx`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`    const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`mdxNode`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(node`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` as `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`unknown`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` as generated.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Mdx`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`    const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`route`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`toLower`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`createFilePath`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`{ `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`node`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`getNode`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` }`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`))`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`createBlogpostHistoryNodeField`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(args`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`route)`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`  }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`}`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span></code></pre>
    <p>{`You can find the code of the function
`}<a parentName="p" {...{
        "href": "https://github.com/hasparus/homepage/blob/4f4025aaabf8053dde3155c571f80594f9c96799/gatsby-node-ts.ts#L233-L287"
      }}>{`here`}</a>{`.
Adding the `}<a parentName="p" {...{
        "href": "https://github.com/hasparus/homepage/blob/7e04cd4775078e8177213ea26a53071364c213d2/src/layouts/PostLayout.tsx#L123-L166"
      }}>{`UI`}</a>{` to the post layout was fairly straightforward.`}</p>
    <p><em parentName="p">{`to be continued`}</em></p>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .night-owl-no-italics {
background-color: #011627;
color: #d6deeb;
}

.night-owl-no-italics .mtk1 { color: #D6DEEB; }
.night-owl-no-italics .mtk2 { color: #011627; }
.night-owl-no-italics .mtk3 { color: #C792EA; }
.night-owl-no-italics .mtk4 { color: #82B1FF; }
.night-owl-no-italics .mtk5 { color: #ADDB67; }
.night-owl-no-italics .mtk6 { color: #637777; }
.night-owl-no-italics .mtk7 { color: #82AAFF; }
.night-owl-no-italics .mtk8 { color: #F78C6C; }
.night-owl-no-italics .mtk9 { color: #7FDBCA; }
.night-owl-no-italics .mtk10 { color: #FF5874; }
.night-owl-no-italics .mtk11 { color: #FFEB95; }
.night-owl-no-italics .mtk12 { color: #FFCB8B; }
.night-owl-no-italics .mtk13 { color: #7FDBCAFF; }
.night-owl-no-italics .mtk14 { color: #80CBC4; }
.night-owl-no-italics .mtk15 { color: #FF6363; }
.night-owl-no-italics .mtk16 { color: #57EAF1; }
.night-owl-no-italics .mtk17 { color: #5F7E97; }
.night-owl-no-italics .mtk18 { color: #ECC48D; }
.night-owl-no-italics .mtk19 { color: #B2CCD6; }
.night-owl-no-italics .mtk20 { color: #FAD430; }
.night-owl-no-italics .mtk21 { color: #FFFFFF; }
.night-owl-no-italics .mtk22 { color: #FF2C83; }
.night-owl-no-italics .mtk23 { color: #020E14; }
.night-owl-no-italics .mtk24 { color: #D3423E; }
.night-owl-no-italics .mtk25 { color: #EC5F67; }
.night-owl-no-italics .mtk26 { color: #8BD649; }
.night-owl-no-italics .mtk27 { color: #5CA7E4; }
.night-owl-no-italics .mtk28 { color: #A2BFFC; }
.night-owl-no-italics .mtk29 { color: #EF535090; }
.night-owl-no-italics .mtk30 { color: #ADDB67FF; }
.night-owl-no-italics .mtk31 { color: #697098; }
.night-owl-no-italics .mtk32 { color: #FF869A; }
.night-owl-no-italics .mtk33 { color: #DDDDDD; }
.night-owl-no-italics .mtk34 { color: #E0DEC6; }
.night-owl-no-italics .mtk35 { color: #CDEBF7; }
.night-owl-no-italics .mtk36 { color: #D9F5DD; }
.night-owl-no-italics .mtk37 { color: #6AE9F0; }
.night-owl-no-italics .mtk38 { color: #C789D6; }
.night-owl-no-italics .mtk39 { color: #BEC5D4; }
.night-owl-no-italics .mtk40 { color: #31E1EB; }
.night-owl-no-italics .mtk41 { color: #8EACE3; }
.night-owl-no-italics .mtk42 { color: #78CCF0; }
.night-owl-no-italics .mtk43 { color: #FAF39F; }
.night-owl-no-italics .mtk44 { color: #D7DBE0; }
.night-owl-no-italics .mtk45 { color: #7986E7; }
.night-owl-no-italics .mtki { font-style: italic; }
.night-owl-no-italics .mtkb { font-weight: bold; }
.night-owl-no-italics .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      